<template>
  <LoadingDialog v-if="loading" />
  <div v-else>
    <CancelInterview
      :cancelInterview="confirmScheduledSlot"
      :scheduledSlot="scheduledSlot"
      :isCancelled="interview.isConfirmed"
      :text="text"
    />
    <v-layout class="mt-3" align-center justify-center>
      <v-flex xs12 sm8 md4>
        <AddToCalendarCard
          v-if="interview.isConfirmed"
          :interview="interview"
          :scheduledSlot="scheduledSlot"/>
      </v-flex>
    </v-layout>
  </div>

</template>

<script>
import * as Sentry from '@sentry/browser'
import CancelInterview from '@/components/cancel-interview'
import LoadingDialog from '@/components/LoadingDialog'
import { getScheduledSlot, confirmScheduledSlot } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import AddToCalendarCard from '@/components/AddToCalendarCard'

export default {
  components: {
    CancelInterview,
    LoadingDialog,
    AddToCalendarCard
  },
  props: {
    confirmToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      scheduledSlot: {
        isoDate: null,
        startTime: null,
        companyNameJa: null,
        applicantName: null
      },
      interview: {
        isCancelled: null, canclledBy: null
      },
      loading: true,
      text: {}
    }
  },
  methods: {
    async confirmScheduledSlot () {
      try {
        await confirmScheduledSlot({ confirmToken: this.confirmToken })
      } catch (err) {
        console.error('Problem confirming interview', err) // eslint-disable-line no-console
        Sentry.captureException(err)
      } finally {
        this.loadData(true)
      }
    },
    async loadData (initialConfirm) {
      this.loading = true
      const decodedToken = decodeURIComponent(this.confirmToken)
      const { data: { scheduledSlot, interview } } = await getScheduledSlot({
        cancelToken: decodedToken
      })
      this.scheduledSlot = scheduledSlot
      this.interview = interview

      const { isConfirmed } = this.interview

      if (!isConfirmed) {
        this.text = {
          title: '面接日時変更の承諾',
          subtitle: '面接日時の変更依頼を承諾します。よろしいですか？'
        }
      } else if (initialConfirm) {
        this.text = {
          title: '面接日時の変更完了',
          subtitle: '日時変更をご了承いただきまして、ありがとうございました。'
        }
      } else {
        this.text = {
          title: 'ご了承済みの日時変更',
          subtitle: '下記日時へ面接日時を変更することを、すでにご了承いただいております。'
        }
      }

      this.loading = false
    }
  },
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_APPLICANT_CONFIRM)
    this.loadData()
  }
}
</script>

<style>
</style>
