<template>
  <v-layout class="mt-3" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card v-if="!isAborted">
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">{{ title }}</h3>
            {{ subtitle }}
          </div>
        </v-card-title>
        <v-list>
          <v-list-item v-if="!isOwner">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.cancelInterview.companyName")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ companyNameJa }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.cancelInterview.applicantName")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ applicantName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.cancelInterview.date")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.cancelInterview.time")
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ time }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions v-if="!isCancelled">
          <v-spacer />
          <v-btn
            @click="checkOnCancelInterview"
            :disabled="isCancelled"
            :loading="processing"
            color="amber lighten-2"
            >{{ $t("m.yes") }}</v-btn
          >
          <v-btn
            outlined
            color="amber lighten-2"
            :disabled="processing || isCancelled"
            @click="isAborted = true"
            >{{ $t("m.no") }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title primary-title>
          <div>{{ $t("m.cancelInterview.operationCanceled") }}</div>
        </v-card-title>
      </v-card>
    </v-flex>

    <Popup
      v-bind="applicantNotificationProp"
      @confirm="onCancelInterview(true)"
      @cancel="onCancelInterview(false)"
      @update:showDialog="showApplicantNotificationConfirm = false"
    />
  </v-layout>
</template>

<script>
// XXX: Change the component name to InterviewActionConfirmation!!!!!!!!!!!
import { format, parseISO } from 'date-fns'
import { serviceName } from '@/truffle.config'
import jaLocale from 'date-fns/locale/ja'
import Popup from '@/components/calendar/Popup'

export default {
  components: {
    Popup
  },
  props: {
    scheduledSlot: {
      type: Object,
      required: true
    },
    cancelInterview: {
      type: Function,
      required: true
    },
    isCancelled: {
      type: Boolean,
      required: true
    },
    text: {
      type: Object,
      required: true
    },
    isOwner: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data () {
    return {
      isAborted: false,
      processingCancelInterview: false,
      showApplicantNotificationConfirm: false
    }
  },
  computed: {
    date () {
      const isoDate = parseISO(this.scheduledSlot.isoDate)
      return format(isoDate, 'yyyy年MMMdd日', { locale: jaLocale })
    },
    time () {
      return this.scheduledSlot.startTime
    },
    companyNameJa () {
      return this.scheduledSlot.companyNameJa
    },
    applicantName () {
      return this.scheduledSlot.applicantName
    },
    processing () {
      return this.processingCancelInterview
    },
    title () {
      return this.text.title
    },
    subtitle () {
      return this.text.subtitle
    },
    applicantNotificationProp () {
      return {
        showDialog: this.showApplicantNotificationConfirm,
        title: this.$t('m.cancelInterview.informApplicantTitle'),
        subtitle: this.$t('m.cancelInterview.informApplicantSubtitle', { serviceName }),
        confirm: this.$t('m.yes'),
        cancel: this.$t('m.no')
      }
    }
  },
  methods: {
    async onCancelInterview (notifyApplicant = true) {
      try {
        this.showApplicantNotificationConfirm = false
        this.processingCancelInterview = true
        await this.cancelInterview(notifyApplicant)
      } finally {
        this.processingCancelInterview = false
      }
    },
    async checkOnCancelInterview () {
      if (this.isOwner) {
        this.showApplicantNotificationConfirm = true
      } else {
        await this.onCancelInterview()
      }
    }
  }
}
</script>

<style>
</style>
